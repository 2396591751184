<template>
  <BaseFilterDrawer :selected-filter-count="selectedFilterCount" @reset="filterMixin_resetFilter">
    <v-text-field
      :value="filters.title"
      :label="$t('title')"
      clearable
      @input="filterMixin_handleDebounce('title', $event)"
    />

    <v-select
      :value="filters.type"
      :label="$t('type')"
      :items="inventoryItemTypes"
      clearable
      @input="filterMixin_updateFilter('type', $event)"
    />

    <BaseAutocomplete
      :value="filters.client_contact_id"
      :label="$t('client')"
      :search-function="searchClients"
      :get-by-id-function="getClientById"
      clearable
      hide-details
      @input="filterMixin_updateFilter('client_contact_id', $event)"
    />

    <v-text-field
      :value="filters.quantity_in_warehouse_from"
      :label="$t('quantity_in_warehouse_from')"
      type="number"
      clearable
      @input="filterMixin_handleDebounce('quantity_in_warehouse_from', $event)"
    />

    <v-text-field
      :value="filters.quantity_in_warehouse_to"
      :label="$t('quantity_in_warehouse_to')"
      type="number"
      clearable
      @input="filterMixin_handleDebounce('quantity_in_warehouse_to', $event)"
    />

    <v-checkbox
      :input-value="filters.only_low_stock"
      :label="$t('only_low_stock')"
      @change="filterMixin_updateFilter('only_low_stock', $event)"
    />
  </BaseFilterDrawer>
</template>

<script>
import { INVENTORY_ITEM_TYPES } from '@/api/inventory-item-service';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import contactService from '@/api/contact-service';
import filterMixin from '@/mixins/filter-mixin';
import BaseFilterDrawer from '@/components/filters/BaseFilterDrawer';

export default {
  name: 'InventoryItemFilter',

  components: { BaseFilterDrawer, BaseAutocomplete },

  mixins: [filterMixin],

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  computed: {
    inventoryItemTypes() {
      return INVENTORY_ITEM_TYPES;
    },
  },

  methods: {
    searchClients: contactService.searchClients,
    getClientById: contactService.getById,
  },
};
</script>
