<template>
  <BaseLoadingOverlay :loading="loading" :z-index="7" hide-spinner>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :loading="loading"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      disable-sort
      @update:page="tableMixin_changePage"
    >
      <template v-slot:item.title="{ item }">
        <div class="d-flex align-center">
          <span class="link" @click="expandRow(item)">
            {{ item.title }}<br />
            <small class="grey--text text-no-wrap">{{ item.code }}</small>
          </span>
          <v-tooltip v-if="isBelowThreshold(item)" transition="none" bottom>
            <template #activator="{ on }">
              <v-icon v-on="on" class="ml-1" color="error" small>mdi-chevron-down-circle</v-icon>
            </template>
            <div>
              {{ $t('quantity_exceeded_min_threshold') }}
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.quantity_in_progress="{ item }">
        <span :class="{ 'grey--text': !+item.quantity_in_progress }">
          {{
            getInventoryItemQuantity(
              item.quantity_in_progress,
              item.material_unit,
              item.unit_a_title,
              item.unit_b_title,
              item.unit_a_b_ratio,
            )
          }}
        </span>
      </template>

      <template v-slot:item.quantity_in_warehouse="{ item }">
        <span :class="{ 'grey--text': !+item.quantity_in_warehouse }">
          {{
            getInventoryItemQuantity(
              item.quantity_in_warehouse,
              item.material_unit,
              item.unit_a_title,
              item.unit_b_title,
              item.unit_a_b_ratio,
            )
          }}
        </span>
      </template>

      <template v-slot:item.quantity_of_legacy_stock="{ item }">
        <BaseNumberCell :value="item.quantity_of_legacy_stock" />
      </template>

      <template v-slot:item.type="{ item }">
        <template v-if="item.type">{{ $t(`inventory_item_types.${item.type}`) }}</template>
      </template>

      <template v-slot:item.dispatch_history="{ item }">
        <router-link
          :to="{
            name: 'inventory-item-dispatches',
            query: { inventory_item_id: item.id, inventory_item_title: item.title },
          }"
          class="link"
        >
          {{ $t('view') }}
        </router-link>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="getRowActions(item)" :item="item" />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.type>
            <template v-if="item.type">{{ $t(`inventory_item_types.${item.type}`) }}</template>
          </template>

          <template v-slot:item.quantity_in_progress>
            <span :class="{ 'grey--text': !+item.quantity_in_progress }">
              {{
                getInventoryItemQuantity(
                  item.quantity_in_progress,
                  item.material_unit,
                  item.unit_a_title,
                  item.unit_b_title,
                  item.unit_a_b_ratio,
                )
              }}
            </span>
          </template>

          <template v-slot:item.quantity_in_warehouse>
            <span :class="{ 'grey--text': !+item.quantity_in_warehouse }">
              {{
                getInventoryItemQuantity(
                  item.quantity_in_warehouse,
                  item.material_unit,
                  item.unit_a_title,
                  item.unit_b_title,
                  item.unit_a_b_ratio,
                )
              }}
            </span>
          </template>

          <template v-slot:item.quantity_of_legacy_stock>
            <BaseNumberCell :value="item.quantity_of_legacy_stock" />
          </template>

          <template v-slot:append>
            <BaseFileDropZone
              :current-files="item.documents"
              :accept="acceptedFileFormats"
              :upload-function="files => uploadFiles(item, files)"
              :delete-function="file => deleteFile(item, file)"
              multiple
            >
              <template v-slot:append>
                <v-divider class="mt-4" />

                <v-tabs v-model="activeTab">
                  <v-tab>
                    {{ $t('inventory_item_table_tabs.in_production') }}
                  </v-tab>
                  <v-tab v-if="showPurchases">
                    {{ $t('inventory_item_table_tabs.purchased') }}
                  </v-tab>
                  <v-tab v-if="showOrderPartMaterials">
                    {{ $t('inventory_item_table_tabs.in_use') }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab" class="mb-4">
                  <v-tab-item>
                    <InventoryItemPartsTable
                      :items="orderPartArray"
                      :inventory-item="item"
                      @dispatch="dispatchOrderPart"
                    />
                  </v-tab-item>

                  <v-tab-item v-if="showPurchases">
                    <div class="d-flex justify-end align-center pt-4 pt-md-0">
                      <v-btn color="primary" @click="createInventoryItemPurchase(item)">
                        <v-icon left>mdi-plus</v-icon>
                        {{ $t('create_purchase') }}
                      </v-btn>
                    </div>

                    <InventoryItemPurchaseTable
                      :rows="inventoryItemPurchaseArray"
                      :inventory-item="item"
                      show-dispatch-button
                      @dispatch="dispatchInventoryItemPurchase"
                      @edit="
                        selectedInventoryItem = item;
                        crudMixin_openForm('inventoryItemPurchase', $event);
                      "
                      @update="updateInventoryItemPurchase"
                      @delete="
                        crudMixin_delete(
                          deleteInventoryItemPurchase,
                          'inventoryItemPurchase',
                          $event,
                        )
                      "
                    />
                  </v-tab-item>

                  <v-tab-item v-if="showOrderPartMaterials">
                    <OrderPartMaterialTable
                      :rows="item.order_part_materials"
                      can-change-location
                      can-change-status
                      dense
                      @update="$emit('update:order-part-material', item, $event)"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </BaseFileDropZone>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>

    <BaseDialog v-model="isInventoryItemPurchaseFormOpen" max-width="1000" persistent scrollable>
      <InventoryItemPurchaseForm
        :dialog="isInventoryItemPurchaseFormOpen"
        :form-item="inventoryItemPurchaseFormItem"
        :inventory-item="selectedInventoryItem"
        @cancel="isInventoryItemPurchaseFormOpen = false"
        @create="crudMixin_created('inventoryItemPurchase', $event, true)"
        @update="
          crudMixin_updated('inventoryItemPurchase', {
            ...inventoryItemPurchaseFormItem,
            ...$event,
          })
        "
      />
    </BaseDialog>

    <v-dialog
      v-model="isInventoryItemDispatchFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <InventoryItemDispatchForm
        :dialog="isInventoryItemDispatchFormOpen"
        :order-part="selectedOrderPart"
        :inventory-item-purchase="selectedInventoryItemPurchase"
        :inventory-item="selectedInventoryItem"
        @cancel="isInventoryItemDispatchFormOpen = false"
        @create="handleInventoryItemDispatch"
      />
    </v-dialog>
  </BaseLoadingOverlay>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import InventoryItemPartsTable from '@/components/tables/InventoryItemPartsTable';
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemService from '@/api/inventory-item-service';
import InventoryItemDispatchForm from '@/components/forms/InventoryItemDispatchForm';
import BaseFileDropZone from '@/components/base/BaseFileDropZone';
import documentService from '@/api/document-service';
import inventoryItemPurchaseService, {
  getDefaultInventoryItemPurchaseFormItem,
} from '@/api/inventory-item-purchase-service';
import InventoryItemPurchaseForm from '@/components/forms/InventoryItemPurchaseForm';
import InventoryItemPurchaseTable from '@/components/tables/InventoryItemPurchaseTable';
import OrderPartMaterialTable from '@/components/tables/OrderPartMaterialTable';
import BaseLoadingOverlay from '@/components/base/BaseLoadingOverlay';
import { getInventoryItemQuantity } from '@/util/inventory-item-quantity';
import BaseNumberCell from '@/components/base/BaseNumberCell';
import BaseDialog from '@/components/base/BaseDialog';

export default {
  name: 'InventoryItemTable',

  components: {
    BaseDialog,
    BaseNumberCell,
    BaseLoadingOverlay,
    OrderPartMaterialTable,
    InventoryItemPurchaseTable,
    InventoryItemPurchaseForm,
    BaseFileDropZone,
    InventoryItemDispatchForm,
    InventoryItemPartsTable,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin, crudMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hiddenHeaders: {
      type: Array,
      default: () => [],
    },
    showPurchases: {
      type: Boolean,
      default: false,
    },
    showOrderPartMaterials: {
      type: Boolean,
      default: false,
    },
    acceptedFileFormats: {
      type: String,
      default: '.jpeg,.png,.jpg,.gif,.svg,.pdf,.dwg,.dxf',
    },
    fetchCounter: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      orderPartArray: [],
      selectedOrderPart: {},
      isInventoryItemDispatchFormOpen: false,

      inventoryItemPurchaseArray: [],
      inventoryItemPurchaseFormItem: {},
      selectedInventoryItemPurchase: {},

      selectedInventoryItem: {},
      isInventoryItemPurchaseFormOpen: false,

      activeTab: 0, // 0 for order parts, 1 for purchases (with showPurchases true), 2 for order part materials (with showOrderPartMaterials true)
    };
  },

  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('type'),
          value: 'type',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('inventory_item_group'),
          value: 'inventory_item_group.title',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('quantity_in_progress'),
          value: 'quantity_in_progress',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('quantity_available'),
          value: 'quantity_in_warehouse',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('quantity_of_legacy_stock'),
          value: 'quantity_of_legacy_stock',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('unit_a_title'),
          value: 'unit_a_title',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('unit_b_title'),
          value: 'unit_b_title',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('unit_a_b_ratio'),
          value: 'unit_a_b_ratio',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];

      return headers.filter(h => !this.hiddenHeaders.includes(h.value));
    },
  },

  watch: {
    fetchCounter: {
      handler() {
        if (this.rows.length === 1) {
          this.expandRow(this.rows[0]);
        } else {
          this.tableMixin_expandedRows = [];
        }
      },
      immediate: true,
    },
  },

  methods: {
    getInventoryItemQuantity,

    // --------- ui -------------
    expandRow(item) {
      this.tableMixin_expandRow(item);
      this.orderPartArray = item.order_parts;
      this.inventoryItemPurchaseArray = item.purchases;
    },
    getRowActions(item) {
      const actions = [
        {
          callback: p => this.$emit('click:dispatch-history', p),
          label: this.$t('dispatch_history'),
          icon: 'mdi-history',
        },
        {
          callback: p => this.$emit('create-last-order-copy', p),
          label: this.$t('create_last_order_copy'),
          icon: 'mdi-content-copy',
        },
        {
          callback: p => this.$emit('click:price-history', p),
          label: this.$t('price_history'),
          icon: 'mdi-currency-eur',
        },
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
      if (item.type === 'bought' && this.showPurchases) {
        actions.unshift({
          callback: p => this.createInventoryItemPurchase(p),
          label: this.$t('create_purchase'),
          icon: 'mdi-plus',
        });
      }
      return actions;
    },
    isBelowThreshold(r) {
      return (
        r.min_threshold >= 0 && r.min_threshold > +r.quantity_in_progress + +r.quantity_in_warehouse
      );
    },

    // --------- purchases --------------
    createInventoryItemPurchase(inventoryItem) {
      this.selectedInventoryItem = inventoryItem;
      this.inventoryItemPurchaseFormItem = {
        ...getDefaultInventoryItemPurchaseFormItem(),
        inventory_item_id: inventoryItem.id,
      };
      this.isInventoryItemPurchaseFormOpen = true;
    },
    async updateInventoryItemPurchase(item) {
      const { data } = await this.crudMixin_update(
        inventoryItemPurchaseService.update,
        'inventoryItemPurchase',
        item,
      );
      this.crudMixin_updated('inventoryItemPurchase', { ...item, ...data });
    },
    deleteInventoryItemPurchase: inventoryItemPurchaseService.delete,

    // --------- dispatches -------------
    dispatchOrderPart(part, item) {
      this.selectedInventoryItemPurchase = {};
      this.selectedOrderPart = part;
      this.selectedInventoryItem = item;
      this.isInventoryItemDispatchFormOpen = true;
    },

    dispatchInventoryItemPurchase(purchase, item) {
      this.selectedOrderPart = {};
      this.selectedInventoryItemPurchase = purchase;
      this.selectedInventoryItem = item;
      this.isInventoryItemDispatchFormOpen = true;
    },

    handleInventoryItemDispatch(dispatch) {
      if (this.selectedOrderPart.id) {
        this.setOrderPartDispatchedAmount(dispatch);
      } else if (this.selectedInventoryItemPurchase.id) {
        this.setInventoryItemPurchaseDispatchedAmount(dispatch);
      }
      this.selectedOrderPart = {};
      this.selectedInventoryItemPurchase = {};
    },

    setOrderPartDispatchedAmount(dispatch) {
      this.$set(
        this.selectedOrderPart,
        'quantity_issued',
        +this.selectedOrderPart.quantity_issued + +dispatch.quantity,
      );
    },

    // At the moment not in use
    // eslint-disable-next-line no-unused-vars
    setInventoryItemPurchaseDispatchedAmount(dispatch) {
      this.selectedInventoryItemPurchase.dispatches.push(dispatch);
    },

    // ----------- files ---------------
    async uploadFiles(inventoryItem, files) {
      const payload = {
        ...inventoryItem,
        documents: files,
      };
      const res = await inventoryItemService.update(payload);
      this.$set(inventoryItem, 'documents', res.data.documents);
    },
    async deleteFile(inventoryItem, file) {
      await documentService.delete(file);
      this.$set(
        inventoryItem,
        'documents',
        inventoryItem.documents.filter(doc => doc.id !== file.id),
      );
    },
  },
};
</script>

<style scoped></style>
