<template>
  <div class="page-wrapper">
    <div>
      <div class="d-flex pa-4">
        <h1 class="title d-flex align-center">
          {{ pageTitle }}
          <InventoryItemGroupPicker
            :value="selectedGroup"
            :open-on-create="!selectedGroup && !$route.query.title"
            @input="filterByGroup"
          >
            <template v-slot:activator="{ on: onMenu }">
              <v-tooltip open-delay="500" bottom>
                <template #activator="{ on: onTooltip }">
                  <v-btn v-on="{ ...onMenu, ...onTooltip }" class="ml-2" icon>
                    <v-icon>mdi-folder-open</v-icon>
                  </v-btn>
                </template>
                <div>
                  {{ $t('inventory_item_groups') }}
                </div>
              </v-tooltip>
            </template>
          </InventoryItemGroupPicker>
        </h1>
        <v-spacer />
        <BasePrimaryActionButton
          icon="mdi-plus"
          :label="$t('create_inventory_item')"
          @click="crudMixin_openForm('inventoryItem', newInventoryItemTemplate)"
        />
        <InventoryItemFilter
          :filters="filterParamsWithoutGroup"
          @change="onFiltersChange"
          @reset="resetInventoryItemFilters"
        />
      </div>

      <InventoryItemTable
        :rows="decoratedInventoryItemArray"
        :pagination="inventoryItemPagination"
        :loading="$store.getters.loading['get:api/inventory-items']"
        :fetch-counter="fetchCounter"
        :accepted-file-formats="acceptedDocumentFormats"
        show-purchases
        show-order-part-materials
        @change-page="getInventoryItems"
        @delete="deleteInventoryItem"
        @edit="editInventoryItem"
        @create-last-order-copy="createLastOrderCopy"
        @update:order-part-material="updateOrderPartMaterial"
        @click:dispatch-history="
          $router.push({
            name: 'inventory-item-dispatches',
            query: { inventory_item_id: $event.id, inventory_item_title: $event.title },
          })
        "
        @click:price-history="showInventoryItemPriceHistory"
      />
    </div>

    <v-dialog
      v-model="isInventoryItemFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="900"
      persistent
      scrollable
    >
      <InventoryItemForm
        :dialog="isInventoryItemFormOpen"
        :form-item="inventoryItemFormItem"
        :accepted-file-formats="acceptedDocumentFormats"
        @cancel="isInventoryItemFormOpen = false"
        @create="crudMixin_created('inventoryItem', $event)"
        @update="crudMixin_updated('inventoryItem', $event)"
      />
    </v-dialog>

    <InventoryItemPriceHistoryListDialog
      :inventory-item="selectedInventoryItem"
      :dialog="isInventoryPriceHistoryDialogOpen"
      use-external-activator
      @close="isInventoryPriceHistoryDialogOpen = false"
    />
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import inventoryItemService from '@/api/inventory-item-service';
import InventoryItemTable from '@/components/tables/InventoryItemTable';
import InventoryItemForm from '@/components/forms/InventoryItemForm';
import InventoryItemFilter from '@/components/filters/InventoryItemFilter';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import InventoryItemGroupPicker from '@/components/InventoryItemGroupPicker';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import orderService from '@/api/order-service';
import clone from 'just-clone';
import orderPartMaterialService from '@/api/order-part-material-service';
import { updateItemById } from '@/util/array';
import InventoryItemPriceHistoryListDialog from '@/components/InventoryItemPriceHistoryListDialog';

export default {
  name: 'InventoryItems',

  components: {
    InventoryItemPriceHistoryListDialog,
    InventoryItemGroupPicker,
    BasePrimaryActionButton,
    InventoryItemFilter,
    InventoryItemForm,
    InventoryItemTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      inventoryItemArray: [],
      inventoryItemPagination: {
        page: 1,
        rowsPerPage: 50,
      },
      inventoryItemFormItem: {},
      inventoryItemFilterParams: {},
      isInventoryItemFormOpen: false,
      newInventoryItemTemplate: {
        type: 'manufactured',
        quantity_of_legacy_stock: 0,
      },
      selectedGroup: null,
      fetchCounter: 0, // watched in inventory item table, to expand a row if just one result was returned

      selectedInventoryItem: {},
      isInventoryPriceHistoryDialogOpen: false,

      acceptedDocumentFormats: '.jpeg,.png,.jpg,.gif,.svg,.pdf,.dwg,.dxf',
    };
  },

  computed: {
    filterParamsWithoutGroup() {
      const filters = clone(this.inventoryItemFilterParams);
      delete filters.inventory_item_group_id;
      delete filters.inventory_item_group_title;
      return filters;
    },

    pageTitle() {
      return this.selectedGroup?.title || this.$t('finished_goods_warehouse');
    },

    // appends field needed nested OrderPartMaterialTable
    decoratedInventoryItemArray() {
      const items = [];
      for (let i = 0; i < this.inventoryItemArray.length; i++) {
        const item = clone(this.inventoryItemArray[i]);
        for (let j = 0; j < item.order_part_materials.length; j++) {
          item.order_part_materials[j].material = {
            id: item.id,
            title: item.title,
            min_threshold: item.min_threshold,
            type: item.type,
            quantity_in_warehouse: item.quantity_in_warehouse,
            quantity_in_progress: item.quantity_in_progress,
            unit_a_title: item.unit_a_title,
            unit_b_title: item.unit_b_title,
            unit_a_b_ratio: item.unit_a_b_ratio,
            material_unit: item.material_unit,
            unit_a_ratio_amount: item.unit_a_ratio_amount,
            unit_b_ratio_amount: item.unit_b_ratio_amount,
          };
        }
        items.push(item);
      }
      return items;
    },
  },

  created() {
    const { inventory_item_group_id, inventory_item_group_title } = this.$route.query;
    if (inventory_item_group_id && inventory_item_group_title) {
      this.selectedGroup = {
        id: inventory_item_group_id,
        title: inventory_item_group_title,
      };
    }
    this.inventoryItemFilterParams = clone(this.$route.query);
    this.getInventoryItems();
  },

  beforeRouteUpdate(to, from, next) {
    if (
      JSON.stringify(this.inventoryItemFilterParams) !== JSON.stringify(to.query) &&
      to.name === 'inventory-items'
    ) {
      this.inventoryItemFilterParams = clone(to.query);
      this.getInventoryItems();
    }
    next();
  },

  methods: {
    async getInventoryItems(page = 1) {
      await this.crudMixin_getPage(
        inventoryItemService.getPage,
        inventoryItemService.model,
        page,
        this.inventoryItemFilterParams,
      );
      this.fetchCounter += 1;
    },

    filterByGroup(group) {
      const query = clone(this.inventoryItemFilterParams);
      if (query.inventory_item_group_id === group?.id) {
        return;
      }
      this.selectedGroup = group;
      query.inventory_item_group_id = group?.id;
      query.inventory_item_group_title = group?.title;
      this.newInventoryItemTemplate.inventory_item_group_id = group?.id;
      this.newInventoryItemTemplate.inventory_item_group = group;
      this.$router.push({ name: 'inventory-items', query });
    },

    editInventoryItem(item) {
      this.inventoryItemFormItem = item;
      this.isInventoryItemFormOpen = true;
    },

    deleteInventoryItem(item) {
      this.crudMixin_delete(inventoryItemService.delete, 'inventoryItem', item);
    },

    async createLastOrderCopy(inventoryItem) {
      let orders = inventoryItem.order_parts.map(part => part.order);
      if (!orders.length) {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('inventory_item_has_no_orders_yet'));
        return;
      }

      orders = orders.filter(o => !!o?.order_no);
      orders.sort((o1, o2) => (o1.order_no < o2.order_no ? 1 : -1));

      const confirmed = await openConfirmDialog({
        title: this.$t('confirm_last_order_copy').replace('{0}', orders[0].order_no),
        confirmText: this.$t('yes'),
        cancelText: this.$t('no'),
      });
      if (!confirmed) {
        return;
      }

      eventBus.$emit(OPEN_SNACKBAR, {
        text: this.$t('order_copy_is_being_created'),
        showProgress: true,
      });
      orderService.clone(orders[0]).then(res => {
        window.open(
          `${process.env.VUE_APP_PUBLIC_PATH}orders?search=${res.data.order_no}`,
          '_blank',
        );
        eventBus.$emit(OPEN_SNACKBAR, this.$t('order_copy_created'));
      });
    },

    async updateOrderPartMaterial(inventoryItem, orderPartMaterial) {
      const { data } = await orderPartMaterialService.patch(orderPartMaterial);
      for (let i = 0; i < this.inventoryItemArray.length; i++) {
        if (this.inventoryItemArray[i].id === inventoryItem.id) {
          this.$set(
            this.inventoryItemArray[i],
            'order_part_materials',
            updateItemById(this.inventoryItemArray[i].order_part_materials, {
              ...orderPartMaterial,
              ...data,
            }),
          );
        }
      }
      eventBus.$emit(OPEN_SNACKBAR, this.$t('order_part_material_updated'));
    },

    showInventoryItemPriceHistory(inventoryItem) {
      this.selectedInventoryItem = inventoryItem;
      this.isInventoryPriceHistoryDialogOpen = true;
    },

    resetInventoryItemFilters() {
      const query = {};
      const {
        inventory_item_group_id,
        inventory_item_group_title,
      } = this.inventoryItemFilterParams;
      if (inventory_item_group_id && inventory_item_group_title) {
        query.inventory_item_group_id = inventory_item_group_id;
        query.inventory_item_group_title = inventory_item_group_title;
      }
      this.$router.push({ name: 'inventory-items', query });
    },

    onFiltersChange(query) {
      const p = clone(query);
      if (this.selectedGroup?.id) {
        p.inventory_item_group_id = this.selectedGroup.id;
        p.inventory_item_group_title = this.selectedGroup.title;
      }
      this.$router.push({ name: 'inventory-items', query: p });
    },
  },
};
</script>

<style scoped></style>
