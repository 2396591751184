var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('div',[_c('div',{staticClass:"d-flex pa-4"},[_c('h1',{staticClass:"title d-flex align-center"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('InventoryItemGroupPicker',{attrs:{"value":_vm.selectedGroup,"open-on-create":!_vm.selectedGroup && !_vm.$route.query.title},on:{"input":_vm.filterByGroup},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-folder-open")])],1)]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('inventory_item_groups'))+" ")])])]}}])})],1),_c('v-spacer'),_c('BasePrimaryActionButton',{attrs:{"icon":"mdi-plus","label":_vm.$t('create_inventory_item')},on:{"click":function($event){return _vm.crudMixin_openForm('inventoryItem', _vm.newInventoryItemTemplate)}}}),_c('InventoryItemFilter',{attrs:{"filters":_vm.filterParamsWithoutGroup},on:{"change":_vm.onFiltersChange,"reset":_vm.resetInventoryItemFilters}})],1),_c('InventoryItemTable',{attrs:{"rows":_vm.decoratedInventoryItemArray,"pagination":_vm.inventoryItemPagination,"loading":_vm.$store.getters.loading['get:api/inventory-items'],"fetch-counter":_vm.fetchCounter,"accepted-file-formats":_vm.acceptedDocumentFormats,"show-purchases":"","show-order-part-materials":""},on:{"change-page":_vm.getInventoryItems,"delete":_vm.deleteInventoryItem,"edit":_vm.editInventoryItem,"create-last-order-copy":_vm.createLastOrderCopy,"update:order-part-material":_vm.updateOrderPartMaterial,"click:dispatch-history":function($event){return _vm.$router.push({
          name: 'inventory-item-dispatches',
          query: { inventory_item_id: $event.id, inventory_item_title: $event.title },
        })},"click:price-history":_vm.showInventoryItemPriceHistory}})],1),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"transition":"slide-y-reverse-transition","max-width":"900","persistent":"","scrollable":""},model:{value:(_vm.isInventoryItemFormOpen),callback:function ($$v) {_vm.isInventoryItemFormOpen=$$v},expression:"isInventoryItemFormOpen"}},[_c('InventoryItemForm',{attrs:{"dialog":_vm.isInventoryItemFormOpen,"form-item":_vm.inventoryItemFormItem,"accepted-file-formats":_vm.acceptedDocumentFormats},on:{"cancel":function($event){_vm.isInventoryItemFormOpen = false},"create":function($event){return _vm.crudMixin_created('inventoryItem', $event)},"update":function($event){return _vm.crudMixin_updated('inventoryItem', $event)}}})],1),_c('InventoryItemPriceHistoryListDialog',{attrs:{"inventory-item":_vm.selectedInventoryItem,"dialog":_vm.isInventoryPriceHistoryDialogOpen,"use-external-activator":""},on:{"close":function($event){_vm.isInventoryPriceHistoryDialogOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }